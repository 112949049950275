import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as HautParleur } from '../../svgs/haut-parleur.svg';
import StyleWait from './styled';
import Background from '../Background/Background';
import axios from '../../utils/axios';

const Wait = ({ next }) => {
  useEffect(() => {
    const interval = setInterval(() => {
      const currentStep = localStorage.getItem('question_step');
      axios.get('/next-step')
        .then((resp) => {
          if (resp.data.step > currentStep) {
            next(resp.data.step);
          }
        });
    }, 2000);
    return () => {
      clearInterval(interval);
    };
  });

  return (
    <Background>
      <StyleWait>
        <div>
          <HautParleur />
        </div>
      </StyleWait>
    </Background>
  );
};

Wait.propTypes = {
  next: PropTypes.func.isRequired,
};

export default Wait;
