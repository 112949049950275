import React from 'react';
import StyleLoad from './styled';
import { ReactComponent as Logo } from '../../svgs/logo.svg';

const Load = () => (
  <StyleLoad>
    <Logo />
    <p>
      Quizz
    </p>
  </StyleLoad>
);

export default Load;
