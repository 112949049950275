import styled from 'styled-components';
import { colors, headerHeight, borderRadius } from '../../utils/vars';

const StyleHeader = styled.header`
  display: flex;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  align-items: center;
  justify-content: space-between;
  height: ${headerHeight};
  padding: 10px 50px;
  background-color: ${colors.white};
  border-bottom-left-radius: ${borderRadius};
  border-bottom-right-radius: ${borderRadius};

  svg {
    width: 80px;
    height: 80px;
  }
  
  p {
    font-size: 2rem;
    line-height: 3;
    font-weight: 600;
    color: ${colors.scooter};
  }
`;

export default StyleHeader;
