import Axios from 'axios';

const { REACT_APP_DOMAIN } = process.env;

// eslint-disable-next-line
console.log(REACT_APP_DOMAIN);
/**
 * Create Axios instance with with api baseURL from env vars
 */
const axios = Axios.create({ baseURL: `https://api.${REACT_APP_DOMAIN}` });

export default axios;
