import React, {
  createContext, useContext, useState, useEffect,
} from 'react';
import PropTypes from 'prop-types';

import { nanoid } from 'nanoid';

const Context = createContext(null);

/**
 * Context for manage user
 */
const UserContext = ({ children }) => {
  /**
   * String uniq id of user
   */
  const [id] = useState(window.localStorage.getItem('user_id') || nanoid());
  const [firstName, setFirstName] = useState(window.localStorage.getItem('user_first'));
  const [lastName, setLastName] = useState(window.localStorage.getItem('user_last'));

  /**
   * String name of user group
   */
  const [group, setGroup] = useState(window.localStorage.getItem('user_group'));

  /**
   * Save user id in localStorage if changed
   */
  useEffect(() => {
    window.localStorage.setItem('user_id', id);
  }, [id]);

  /**
   * Save user first name in localStorage if changed
   */
  useEffect(() => {
    window.localStorage.setItem('user_first', firstName);
  }, [firstName]);

  /**
   * Save user last name in localStorage if changed
   */
  useEffect(() => {
    window.localStorage.setItem('user_last', lastName);
  }, [lastName]);

  /**
   * Save group in localStorage if changed
   */
  useEffect(() => {
    if (group) {
      window.localStorage.setItem('user_group', group);
    } else {
      window.localStorage.removeItem('user_group');
    }
  }, [group]);

  return (
    <Context.Provider value={{
      /**
       * User ID
       */
      id,
      firstName,
      setFirstName,
      lastName,
      setLastName,

      /**
       * Group name
       */
      group,

      /**
       * Set group name
       */
      setGroup,

      /**
       * Reset data (Actually only group)
       */
      reset: () => setGroup(),
    }}
    >
      {children}
    </Context.Provider>
  );
};

export const useUser = () => useContext(Context);

UserContext.propTypes = {
  children: PropTypes.node.isRequired,
};

export default UserContext;
