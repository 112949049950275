import styled from 'styled-components';
import { colors, borderRadius } from '../../utils/vars';

const StyleBackground = styled.div`
  width: 100%;
  margin-bottom: 50px;
  background-color: ${colors.whisper};
  background-image: url('/svgs/pattern.svg');
  background-repeat: repeat;
  background-size: 250px;
  border-bottom-right-radius: ${borderRadius};
  border-bottom-left-radius: ${borderRadius};
`;
export default StyleBackground;
