import React from 'react';
import styled from 'styled-components';
import { colors, borderRadius } from '../../utils/vars';
import Wrapper from '../../utils/helpers';
import { ReactComponent as Hand } from '../../svgs/hand-like.svg';
import StyleFinish from './styled';

const Finish = () => {
  const Background = styled.div`
    min-height: 100vh;
    background-color: ${colors.whisper};
    background-color: ${colors.whisper};
    background-image: url('/svgs/pattern.svg');
    background-repeat: repeat;
    background-size: 250px;
    border-bottom-right-radius: ${borderRadius};
    border-bottom-left-radius: ${borderRadius};
  `;

  return (
    <Background>
      <Wrapper>
        <StyleFinish>
          <div>
            <p>Merci pour votre participation !</p>
            <p>Les résultats vont être annoncés en direct !</p>
            <Hand />
          </div>
        </StyleFinish>
      </Wrapper>
    </Background>

  );
};

export default Finish;
