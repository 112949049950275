import styled from 'styled-components';
import { colors } from '../../utils/vars';

const StyleCheck = styled.div`
  display: flex;
  position: relative;
  flex-direction: row-reverse;
  align-items: center;
  font-size: 1.4rem;
  font-weight: 600;

  & + & {
    margin-top: 20px;
  }

  &:hover {
    color: ${colors.rhino};
  }

  input {
    position: absolute;
    width: 1px;
    height: 1px;
    margin: -1px;
    padding: 0;
    overflow: hidden;
    clip: rect(0 0 0 0);
    border: 0;

    &:checked {
      & ~ label {
        &::before {
          background-color: ${colors.scooter};
          border-color: ${colors.scooter};
        }

        svg {
          display: block;
        }
      }
    }
    
    &:focus,
    &:checked {
      & ~ label {
        border: 2px solid ${colors.scooter};
        color: ${colors.rhino};
      }
    }
  }

  label {
    display: inline-grid;
    position: relative;
    text-overflow: clip;
    overflow: hidden;
    white-space: pre-wrap;
    width: 100%;
    align-items: center;
    border: 2px solid transparent;
    cursor: pointer;
    border-radius: 30px;
    color: ${colors.wild};
    background-color: ${colors.whisper};
    padding: 20px 45px 20px 20px;
    letter-spacing: 3;
    line-height: 2.4rem;
    transition: color .3s, max-height .5s ease-in-out;

    &::before {
      content: '';
      position: absolute;
      width: 18px;
      height: 18px;
      margin: -1px;
      right: 20px;
      border: 2px solid ${colors.rhino};
      border-radius: ${(p) => (p.type === 'radio' ? '9px' : '4px')};
      background-color: transparent;
    }

    &:hover {
      color: ${colors.rhino};
    }

    svg {
      display: none;
      position: absolute;
      right: 22px;
      width: 12px;
      height: 12px;
      fill: ${colors.white};
    }
  }

`;

export default StyleCheck;
