import styled from 'styled-components';
import { colors, borderRadius } from '../../utils/vars';

const StyleQuestion = styled.form`
  .content-form {
    margin-top: 30px;
    padding: 20px;
    background-color: ${colors.white};
    border-radius: ${borderRadius};

    img {
      margin-bottom: 30px;
    }

    input {
      padding: 20px;
      width: 100%;
      height: 50px;
      border: 2px solid ${colors.wild};
      border-radius: 25px;
      color: ${colors.rhino};
      font-size: 1.4rem;
      transition: border .4s ease;

      &:focus {
        border: 2px solid ${colors.rhino};
      }
    }


    p {
      margin-bottom: 30px;
    }
  }
`;

export default StyleQuestion;
