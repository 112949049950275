import styled from 'styled-components';
import { headerHeight } from './vars';

const Wrapper = styled.div`
    width: 85vw;
    max-width: 1000px;
    margin: 0 auto 50px;
    padding-top: ${headerHeight};
`;

export default Wrapper;
