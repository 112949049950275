import React from 'react';
import PropTypes from 'prop-types';
import StyleButton from './styled';

const Button = ({
  children, onClick, type, disable,
}) => (
  <StyleButton>
    <button
      type={type === 'submit' ? 'submit' : 'button'}
      onClick={onClick}
      disabled={disable}
    >
      {children}
    </button>
  </StyleButton>
);

Button.defaultProps = {
  onClick: undefined,
  type: 'button',
};

Button.propTypes = {
  children: PropTypes.string.isRequired,
  type: PropTypes.string,
  onClick: PropTypes.func,
  disable: PropTypes.bool.isRequired,
};

export default Button;
