import styled from 'styled-components';
import { colors } from '../../utils/vars';

const StyleWait = styled.div`
  display: flex;
  height: 85vh;
  width: 100%;
  padding: 50px 0;
  justify-content: center;
  align-items: center;
  color: ${colors.scooter};
  font-size: 2.4rem;
  font-weight: 700;
  letter-spacing: 3;
  line-height: 3.4rem;
  text-align: center;

  div {
    p + p {
      margin-top: 20px;
    }
    svg {
      margin-top: 30px;
      width: 150px;
      height: 150px;
    }
  }
`;

export default StyleWait;
