import React, {
  createContext, useContext, useState,
} from 'react';
import PropTypes from 'prop-types';

const Context = createContext(null);

/**
 * Context for manage themes status
 */
const ThemeContext = ({ children }) => {
  /**
   * Primary customer color state
   */
  const [primary, setPrimary] = useState('#3A2C77');

  /**
   * Secondary customer color state
   */
  const [secondary, setSecondary] = useState('#26B8CB');

  /**
   * Customer logo state
   */
  const [logo, setLogo] = useState('/logo.png');

  return (
    <Context.Provider value={{
      primary,
      setPrimary,
      secondary,
      setSecondary,
      logo,
      setLogo,

      /**
       * Get object with all states values
       */
      theme: { primary, secondary, logo },
    }}
    >
      {children}
    </Context.Provider>
  );
};

ThemeContext.propTypes = {
  children: PropTypes.node.isRequired,
};

export const useTheme = () => useContext(Context);

export default ThemeContext;
