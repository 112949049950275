import React, { useEffect, useReducer } from 'react';
// eslint-disable-next-line
import './normalize.scss';

import debug from 'debug';
import styled from 'styled-components';
import Group from './components/Group/Group';
import Question from './components/Question/Question';
import Layout from './components/Layout';
import Wait from './components/Wait/Wait';
import Load from './components/LoadingPage/Load';
import axios from './utils/axios';
import { initialState, reducer } from './reducers/Quizz';
import Finish from './components/Finish/Finish';

const log = debug('quizz:app');

const SuperContainer = styled.div`
  position: relative;
`;

function App() {
  // const { group } = useUser();
  const [{
    status, question,
  }, dispatch] = useReducer(reducer, initialState);

  /**
   * Get questions on load
   */
  useEffect(() => {
    axios.get('/quizz')
      .then((res) => res?.data)
      .then((questions) => dispatch({ type: 'SET_QUESTIONS', questions }))
      .catch(log);
  }, []);

  const next = () => dispatch({ type: 'NEXT' });
  const reset = () => dispatch({ type: 'RESET' });

  useEffect(() => {
    let timer;
    if (status === 'loading') {
      timer = setTimeout(() => {
        next();
      }, 2000);
    }
    return () => {
      clearTimeout(timer);
    };
  }, [status]);

  return (
    <SuperContainer>
      <Layout quizzReset={reset} status={status}>
        {status === 'loading' && <Load />}

        {status === 'start' && <Group next={next} />}

        {status === 'wait' && <Wait next={next} />}

        {status === 'play' && question && <Question question={question} next={next} />}

        {status === 'finish' && <Finish />}
      </Layout>
    </SuperContainer>
  );
}

export default App;
