import React from 'react';
import PropTypes from 'prop-types';
import StyleHeader from './styled';
import { ReactComponent as Logo } from '../../svgs/logo.svg';

const Header = ({ status }) => {
  if (status !== 'loading') {
    return (
      <StyleHeader>
        <Logo />
        <p>
          Quizz
        </p>
      </StyleHeader>
    );
  } return null;
};

Header.propTypes = {
  status: PropTypes.string.isRequired,
};
export default Header;
