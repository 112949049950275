import { getItem, setItem, removeItem } from '../utils/localStorage';

export const initialState = {
  /**
   * Actual question
   */
  question: null,

  /**
   * All question list
   */
  questions: [],

  /**
   * Game status :
   *  - start: before playing (select group ?)
   *  - play: when playing
   *  - finish: game finish (show stats)
   */
  status: getItem('question_status') || 'loading',

  /**
   * Questions length
   */
  size: 0,

  /**
   * Actual question index
   * Get from Local Storage if exists, or init to 0
   */
  step: Number(getItem('question_step')) || 0,
};

export const reducer = (state, action) => {
  switch (action.type) {
    /**
     * Reset quizz, keep only questions list
     */
    case 'RESET': {
      setItem('question_step', 0);
      setItem('question_status', 'loading');
      return {
        ...state,
        question: null,
        step: 0,
        status: 'start',
      };
    }

    /**
     * Set questions list
     */
    case 'SET_QUESTIONS': {
      const { questions } = action;

      return {
        ...state,
        questions,
        question: questions[state.step] || null,
        size: questions.length,
      };
    }

    /**
     * Go to next step on quizz
     */
    case 'NEXT': {
      const {
        questions, status, step, question,
      } = state;

      console.log(state);

      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });

      if (status === 'loading') {
        setItem('question_step', 0);
        setItem('question_status', 'start');
        return {
          ...state,
          question: questions[0],
          step: 0,
          status: 'start',
        };
      }

      // Start playing
      if (status === 'start') {
        setItem('question_step', 0);
        setItem('question_status', 'play');
        return {
          ...state,
          question: questions[0],
          step: 0,
          status: 'play',
        };
      }

      const nextStep = step + 1;

      // if is the last question finish quizz
      if (!questions[nextStep]) {
        // Clear storage user datas
        removeItem('user_id');
        removeItem('user_group');
        removeItem('question_status');
        removeItem('question_step');
        removeItem('user_last');
        removeItem('user_first');

        return {
          ...state,
          status: 'finish',
        };
      }

      // If part is changed, show screen before change question
      if (status !== 'wait' && question.part !== questions[nextStep].part) {
        setItem('question_status', 'wait');
        return {
          ...state,
          status: 'wait',
        };
      }

      // Set next question
      setItem('question_step', nextStep);
      setItem('question_status', 'play');
      return {
        ...state,
        question: questions[nextStep],
        step: nextStep,
        status: 'play',
      };
    }

    /**
     * Error if bad type
     */
    default:
      throw new Error(`Action type ${action.type} is not allowed`);
  }
};
