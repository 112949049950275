import React from 'react';
import PropTypes from 'prop-types';
import { createGlobalStyle } from 'styled-components';
import { useTheme } from '../contexts/Theme';
import { colors } from '../utils/vars';
import Header from './Header/Header';

const GlobalStyle = createGlobalStyle`
  body {
    font-family: 'poppins', sans-serif;
    font-size: 1.8rem;
    font-weight: 500;
    line-height: 1.3;
    background-color: ${colors.white};
    color: ${colors.wild};
  }
`;

const Layout = ({ children, status }) => {
  const { secondary, primary } = useTheme();

  return (
    <>
      <GlobalStyle secondary={secondary} primary={primary} />
      <div>
        <Header status={status} />
        {children}
      </div>
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  status: PropTypes.string.isRequired,
};

export default Layout;
