import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import UserContext from './contexts/User';
import ThemeContext from './contexts/Theme';

ReactDOM.render(
  <React.StrictMode>
    <UserContext>
      <ThemeContext>
        <App />
      </ThemeContext>
    </UserContext>
  </React.StrictMode>,
  document.getElementById('root'),
);
