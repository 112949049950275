/**
 * Allow use destructured getItem and setItem
 */

/**
 * get localStorage item
 */
export const getItem = (key) => window.localStorage.getItem(key);

/**
 * Set localStorage item
 */
export const setItem = (key, value) => window.localStorage.setItem(key, value);

/**
 * Delete localStorage item
 */
export const removeItem = (key) => window.localStorage.removeItem(key);
