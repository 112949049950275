import styled from 'styled-components';
import { colors } from '../../utils/vars';

const StyleGroup = styled.div`
    input {
      padding: 20px;
      width: 100%;
      height: 50px;
      border: 0;
      border-radius: 25px;
      color: ${colors.rhino};
      font-size: 1.4rem;

      &::placeholder {
        color: ${colors.wild};
      }
    }

    input + input {
      margin-top: 10px;
    }
`;

export default StyleGroup;
