import styled from 'styled-components';
import { colors } from '../../utils/vars';

const StyleText = styled.p`
  font-size: 1.8rem;
  font-weight: 500;
  letter-spacing: 3;
  line-height: 2.4rem;
  color: ${colors.rhino};
  text-align: ${((props) => props.align)};
`;

export default StyleText;
