import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import Button from '../Button/Button';
import Text from '../Text/Text';
import GroupCard from '../GroupCard/GroupCard';
import { useUser } from '../../contexts/User';
import StyleGroup from './styled';
import Background from '../Background/Background';

function Group({ next }) {
  const Margin = styled.div`
    margin: 30px;
  `;
  const GroupList = styled.ul`
    margin: 0 auto;

    div {
      display: flex;
      justify-content: center;

      li + li {
        margin-left: 20px;
      }
    }

    div + div {
      margin-top : 30px;
    }
  `;

  const {
    group,
    firstName,
    lastName,
    setFirstName,
    setLastName,
  } = useUser();

  return (
    <Background>
      <StyleGroup>
        <Margin>
          <Text align="center">
            Nom et prénom
          </Text>
        </Margin>
        <input
          type="text"
          placeholder="Nom"
          onChange={(e) => setLastName(e.target.value)}
        />
        <input
          type="text"
          placeholder="Prénom"
          onChange={(e) => setFirstName(e.target.value)}
        />
        <Margin>
          <Text align="center">
            Sélectionnez votre groupe :
          </Text>
        </Margin>
        <GroupList>
          <div>
            <GroupCard name="A" />
            <GroupCard name="B" />
            <GroupCard name="C" />
          </div>
          <div>
            <GroupCard name="D" />
            <GroupCard name="E" />
          </div>
        </GroupList>
        <Button onClick={group && firstName && lastName ? next : ''} type="button">
          { !group || !firstName || !lastName ? 'En attente...' : `Equipe ${group}`}
        </Button>
      </StyleGroup>
    </Background>
  );
}

Group.propTypes = {
  next: PropTypes.func.isRequired,
};

export default Group;
