import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import isArray from 'lodash/isArray';
import PropTypes from 'prop-types';
// import youtubeEmbed from 'youtube-embed';
import axios from '../../utils/axios';
import Button from '../Button/Button';
import StyleQuestion from './styled';
import { useUser } from '../../contexts/User';
import Text from '../Text/Text';
import Check from '../Check/Check';
import Background from '../Background/Background';
import Wait from '../Wait/Wait';

const Question = ({ question, next }) => {
  const { register, handleSubmit } = useForm();
  const [disable, setDisable] = useState(false);
  const {
    id, group, firstName, lastName,
  } = useUser();

  useEffect(() => {
    const interval = setInterval(() => {
      const currentStep = localStorage.getItem('question_step');
      axios.get('/next-step')
        .then((resp) => {
          if (resp.data.step > currentStep) {
            next();
          }
        });
    }, 2000);
    return () => {
      clearInterval(interval);
    };
  });

  /**
   * If not has questions, disable this component
   */
  if (!question) {
    return null;
  }

  /**
   * Send user response to API, and go to next step
   */
  const onSubmit = async ({ responses }) => {
    // prevent if responses is null
    const res = responses !== null ? responses : [];

    await axios.post('/question/', {
      user: id,
      firstName,
      lastName,
      group,
      part: question.part,
      questionId: question._id,
      question: question.question,
      responses: isArray(res) ? res : [res],
    })
      .then(setDisable(true));
  };

  // eslint-disable-next-line react/prop-types
  const Picture = ({ mediaUrl }) => {
    console.log(mediaUrl);
    return (
      <img
        src={mediaUrl}
        alt=""
      />

    );
  };

  // eslint-disable-next-line react/prop-types
  // const Video = ({ mediaUrl }) => (
  //   <iframe
  //     className="question-video"
  //     src={youtubeEmbed(mediaUrl)}
  //     title={question.question}
  //     frameBorder="0"
  //     allow="accelerometer;
  // autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
  //     allowFullScreen
  //   />
  // );

  /**
   * Question type must is checkbox or radio, or throw exception
   */
  if (question.type === 'wait') return <Wait next={next} />;
  if (['checkbox', 'radio', 'mixedWord', 'video', 'picture'].includes(question.type)) {
    return (
      <Background>
        <StyleQuestion onSubmit={handleSubmit(onSubmit)}>
          <div className="content-form">
            <Text>
              {question.question}
            </Text>
            {/* {
              question.mediaUrl && question.type === 'video' ? (
                <Video
                  mediaUrl={question.mediaUrl}
                />
              ) : null
            } */}
            {
              question.mediaUrl && question.type !== 'video' ? (
                <Picture
                  mediaUrl={question.mediaUrl}
                />
              ) : null
            }
            {
              question.type === 'mixedWord' ? (
                <input
                  type="text"
                  name="responses"
                  ref={register}
                />
              ) : (
                question.responses.map(({ value, label }) => (
                  <Check
                    id={value}
                    key={value}
                    label={label}
                    register={register}
                    type={question.type}
                  />
                ))
              )
            }
          </div>
          <Button type="submit" disable={disable}>
            {disable ? 'Envoyé' : 'Valider'}
          </Button>
        </StyleQuestion>
      </Background>
    );
  }
  throw new Error(`${question.type} is not good type checkbox or radio`);
};

Question.propTypes = {
  question: PropTypes.shape({
    question: PropTypes.string.isRequired,
    mediaUrl: PropTypes.string.isRequired,
    responses: PropTypes.arrayOf(PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    })).isRequired,
    type: PropTypes.oneOf(['checkbox', 'radio']),
    _id: PropTypes.string.isRequired,
    part: PropTypes.string.isRequired,
  }).isRequired,
  next: PropTypes.func.isRequired,
};

export default Question;
