import React from 'react';
import PropTypes from 'prop-types';
import StyleText from './styled';

const Text = ({ align, children }) => (
  <StyleText align={align}>
    {children}
  </StyleText>
);

Text.defaultProps = {
  align: 'left',
};

Text.propTypes = {
  children: PropTypes.string.isRequired,
  align: PropTypes.string,
};

export default Text;
