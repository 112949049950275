import styled from 'styled-components';
import { colors } from '../../utils/vars';

const StyleButton = styled.p`
  display: flex;
  justify-content: center;
  transform: translateY(50%);
  margin: 30px 0 50px;
  transition: all 0.3s linear;

  button {
    padding: 10px 40px;
    height: 46px;
    background-color: ${colors.minsk};
    border-radius: 90px;
    outline: 0;
    color: ${colors.white};
    font-size: 1.6rem;
    font-weight: 600;
    
    &:focus {
      box-shadow: 0 0 0 2px ${colors.scooter};
    }

    &:hover {
      background-color: ${colors.wild};
    }

    &:disabled {
      background-color: ${colors.wild};
    }
  }
`;

export default StyleButton;
