export const colors = {
  white: '#fff',
  black: '#000',
  minsk: '#3a2c77',
  scooter: '#26b8c8',
  whisper: '#eef0f6',
  wild: '#8588b2',
  rhino: '#32355e',
};

export const headerHeight = '75px';

export const borderRadius = '30px';
