import React from 'react';
import PropTypes from 'prop-types';
import StyleCheck from './styled';
import { ReactComponent as Check } from '../../svgs/check.svg';

const Input = ({
  id, label, type, register,
}) => (
  <StyleCheck type={type}>
    <input id={id} type={type} name="responses" ref={register} value={id} />
    <label htmlFor={id}>
      {label}
      <Check />
    </label>
  </StyleCheck>
);

Input.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  type: PropTypes.oneOf(['radio', 'checkbox']).isRequired,
  register: PropTypes.func.isRequired,
};

export default Input;
