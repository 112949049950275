import styled from 'styled-components';
import { colors } from '../../utils/vars';

const StyleLoad = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100%;
  background-color: ${colors.whisper};
  background-image: url('/svgs/pattern.svg');
  background-repeat: repeat;
  background-size: 250px;

  svg {
    width: 200px;
    height: 150px;
  }

  p {
    color: ${colors.scooter};
    font-size: 2.4rem;
    font-weight: 700;
    line-height: 3.4rem;
    letter-spacing: 3;
  }
`;
export default StyleLoad;
