import styled from 'styled-components';
import { colors } from '../../utils/vars';

const StyleGroupCard = styled.li`
  display: flex;
  flex-direction: column;
  width: 30%;
  background-color: ${colors.white};
  border: ${(p) => (p.active ? `2px solid ${colors.scooter}` : '2px solid transparent')};
  border-radius: 20px;
  box-shadow: 8px 8px 15px 0px rgba(0, 0, 0, .05);
  cursor: pointer;

  button {
    padding: 20px 15px;
    border-radius: 20px;
    outline: none;
  }

  figure{
    display: flex;
    width: 100%;
    margin-bottom: 20px;
    padding: 10px;
    align-items: center;
    justify-content: center;
    background-color: ${(p) => (p.active ? colors.scooter : colors.whisper)};
    border-radius: 50%;

    svg {
      width: 100%;
      height: 100%;
      fill: ${(p) => (p.active ? colors.white : colors.wild)};
    }
  }

  p {
    color: ${(p) => (p.active ? colors.rhino : colors.wild)};
    font-size: 1.3rem;
    font-weight: 600;
    text-align: center;
  }
`;

export default StyleGroupCard;
