import React from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as Man } from '../../svgs/man.svg';
import StyleGroupCard from './styled';
import { useUser } from '../../contexts/User';

const GroupCard = ({ name }) => {
  const { setGroup, group } = useUser();

  return (
    <StyleGroupCard active={group === name}>
      <button type="button" onClick={() => setGroup(name)}>
        <figure>
          <Man />
        </figure>
        <p>
          GROUPE
          {' '}
          {name}
        </p>
      </button>
    </StyleGroupCard>
  );
};

GroupCard.propTypes = {
  name: PropTypes.string.isRequired,
};

export default GroupCard;
