import styled from 'styled-components';
import { colors, headerHeight } from '../../utils/vars';

const StyleWait = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: calc(80vh - ${headerHeight});
  padding: 50px 20px;
  justify-content: center;
  text-align: center;
  color: ${colors.scooter};
  font-size: 2.4rem;
  font-weight: 700;
  letter-spacing: 3;
  line-height: 3.4rem;

  div {
    p {
      max-width: 18ch;
      margin-bottom: 50px;
    }
  }
  svg {
    width: 130px;
    height: 130px;
  }
`;

export default StyleWait;
