import React from 'react';
import PropTypes from 'prop-types';
import StyleBackground from './styled';
import Wrapper from '../../utils/helpers';

const Background = ({ children }) => (
  <StyleBackground>
    <Wrapper>
      {children}
    </Wrapper>
  </StyleBackground>
);

Background.propTypes = {
  children: PropTypes.node.isRequired,
};
export default Background;
